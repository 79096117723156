import React, { useEffect } from "react";
import TimingBanner from "./TimingBanner";
import { useApp } from "../contexts/app/appContext";
import { firestore } from "../utils/firebase";
import axios from 'axios'

const FormTimer = () => {
  const {
    state: {
      submissionId,
      settings,
      submissionStatus: { start, end },
    },
    dispatch,
  } = useApp();

  const updateFormWatcher =  async (formId: string, formCreatorId: string, ) => {
    if (!formCreatorId) console.error('formCreatorId is required')

    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/pubsub-check/watch/${formId}`, {
      "email": formCreatorId
    })

    if (res.data.success) console.log('Successfully updated form watcher');
  }

  // listen to submissions for closing on submission
  useEffect(() => {
    if (settings && submissionId) {
      firestore
        .collection("forms")
        .doc(settings.formId)
        .collection("submissions")
        .where("submitted", "==", submissionId)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            console.log("received submission end trigger");
            dispatch({ type: "SET_END", payload: new Date() });
          }
        });

      updateFormWatcher(settings.formId, settings.formCreatorId || '');
    }
  }, [dispatch, settings, submissionId]);

  if (!settings || !submissionId) {
    return <div />;
  }
  const { prefilledFormId, htmlEntry } = settings;

  const prefilledFormUrl = `https://docs.google.com/forms/d/e/${prefilledFormId}/viewform?usp=pp_url&entry.${htmlEntry}=${submissionId}`;

  return (
    <div
      className="d-flex flex-column flex-grow-1"
      style={{ backgroundColor: "white" }}
    >
      <TimingBanner hasEnded={start && end ? { start, end } : undefined} />

      <div
        className="d-flex flex-column flex-grow-1"
        style={{ position: "relative" }}
      >
        {prefilledFormUrl && (
          <div className="d-flex flex-column flex-grow-1">
            <iframe
              className="w-100 flex-grow-1"
              id="formiframe"
              title="form"
              src={prefilledFormUrl}
              // width="100%"
              frameBorder="0"
            >
              Loading…
            </iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormTimer;
