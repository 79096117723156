import React, { useCallback, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useApp } from '../contexts/app/appContext'
import { getForm } from '../utils/db'
import { logFormStart } from '../utils/logFormStart'
import { isOutOfBounds } from '../utils/time'

import LoadingPage from './LoadingPage'
import WelcomePage from './WelcomePage'


const Start = () => {
  const {
    state: { settings },
    dispatch,
  } = useApp()

  const { lookupId } = useParams() as { lookupId: string }

  const history = useHistory()

  const storeSubmissionId = useCallback(
    (submissionId: string) => {
      dispatch({
        type: 'SET_SUBMISSION_ID',
        payload: { submissionId },
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const initializeApp = async () => {
      if (lookupId) {
        localStorage.setItem('lookupId', lookupId)
        console.log('SAVED LOOKUP ID', lookupId)

        const settings = await getForm(lookupId)
        console.log('settings', settings)
        if (settings) {

          if (
              isOutOfBounds({
                startDate: settings.startDate,
                endDate: settings.endDate,
              }) !== 'no'
          ) {
            dispatch({ type: 'INITIALIZE_APP', payload: settings })
            history.push('/closed')
          } else if (settings.hideTimer) {
            await logFormStart({ formId: settings.formId, storeSubmissionId, formCreatorId: settings?.formCreatorId || '' })
            dispatch({ type: 'INITIALIZE_APP', payload: settings })
            history.push('/app')
          } else {
            dispatch({ type: 'INITIALIZE_APP', payload: settings })
          }
        } else {
          console.error('could not retrieve form data')
          // TODO: HANDLE ERROR
        }
      }
    }

    initializeApp()
  }, [dispatch, history, lookupId, storeSubmissionId])

  return (
    <>{!!settings ? <WelcomePage settings={settings} /> : <LoadingPage />}</>
  )
}
export default Start
